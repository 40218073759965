import React, { createContext, useState, useEffect } from 'react';
import en from '../translations/en.json';
import es from '../translations/es.json';

const TranslationContext = createContext();

// Define the translations object
const translations = { en, es };

// Helper function to access nested keys in the translation object
const getNestedTranslation = (dictionary, key) => {
  return key.split('.').reduce((obj, level) => obj && obj[level], dictionary) || key;
};

// Create a provider component
export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || navigator.language.split('-')[0] || 'en');
  const [dictionary, setDictionary] = useState(translations[language]);

  // Update dictionary when language changes
  useEffect(() => {
    localStorage.setItem('language', language);
    setDictionary(translations[language] || translations['en']); // Fallback to English if the selected language is not found
  }, [language]);

  // Translation function
  const t = (key) => getNestedTranslation(dictionary, key);

  // Change language function
  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <TranslationContext.Provider value={{ t, changeLanguage, language }}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationContext;
