import React, { useContext } from 'react';
import classes from './ContactComponent.module.css';
import SceneStudio from './SceneStudio';

import TranslationContext from '../hooks/TranslationProvider';

export default function ContactComponent({backgroundColor = '#fff', balls = false}){
    const { t } = useContext(TranslationContext);

    return(
        <div className={balls ? classes.contactContainer : null} style={{backgroundColor: backgroundColor}}>
            <div className={classes.infoContainer}>
                <h2>{t('contact.work')}</h2>
                <h1>{t('contact.contact')}</h1>
                <a className={classes.mail} href="mailto:contacto@aztech-mexico.com" target="_blank" rel='noreferrer'>contacto@aztech-mexico.com</a>
                <div className={classes.people}>
                    <div className={classes.fernando}>
                        <a href="tel:+528442779736" target="_blank" rel='noreferrer'>+52 844 277 9736</a>
                    </div>
                    <div className={classes.ricardo}>
                        <a href="tel:+528443422687" target="_blank" rel='noreferrer'>+52 844 342 2687</a>
                    </div>
                </div>
                <div className={classes.footer}>
                    <p>{t('contact.dev')}</p>
                    <p>{t('contact.copyright')}</p>
                </div>
            </div>

            {
                balls &&    <div className={classes.figuresContainer}>
                                <SceneStudio />
                            </div>
            }
        </div>
    );
};