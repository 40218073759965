import { useEffect, useRef, useState, useContext } from "react";
import { gsap } from "gsap";
import TranslationContext from "../hooks/TranslationProvider";

import classes from "./AnimatedText.module.css";

const AnimatedText = () => {
  const { t } = useContext(TranslationContext);
  const wordRef = useRef(null);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const words = [
    t("studio.weAre.text1"),
    t("studio.weAre.text2"),
    t("studio.weAre.text3"),
    t("studio.weAre.text4"),
  ];

  useEffect(() => {
    let tl;
    const cycleWords = () => {
      if (wordRef.current) {
        tl = gsap.timeline();
        // Animate the new word in from below (move up to place)
        tl.fromTo(
          wordRef.current,
          { y: 15, opacity: 0 }, // Start below and invisible
          { y: 0, opacity: 1, duration: 0.75, ease: "power1.out" } // Animate to place and fade in
        )
          .to(wordRef.current, { y: 0, opacity: 1, duration: 1.5 }) // Stay in place for 2 seconds
          .to(wordRef.current, {
            y: 15, // Move down out of view
            opacity: 0,
            duration: 0.75,
            ease: "power1.in",
            onComplete: () => {
              // Change the word after the out animation completes
              setCurrentWordIndex((prev) => {
                let wordIndex = prev + 1;
                if (wordIndex > words.length - 1) {
                  wordIndex = 0;
                }
                return wordIndex;
              });
            },
          });
        tl.add(cycleWords, "+=1"); // Delay of 1 second before restarting the cycle
      }
    };
    cycleWords();
  }, []);

  return (
    <div className={classes.textContainer}>
      <h1>
        {t("studio.weAre.weAre")}{" "}
        <span ref={wordRef}>{words[currentWordIndex]}</span>
      </h1>
    </div>
  );
};

export default AnimatedText;
