import { Outlet } from "react-router-dom";
import MainNavigation from "../components/MainNavigation.js";
import PageTransition from "../components/PageTransition.js";


function RootLayout(){
    return (
        <>
            <MainNavigation/>
            <main>
                <PageTransition>
                    <Outlet />
                </PageTransition>
            </main>
        </>
    );
}

export default RootLayout;