import classes from './SceneProject.module.css';
import { Canvas } from "@react-three/fiber";
import { useGLTF, Environment } from '@react-three/drei';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { useGSAP } from '@gsap/react';

import laptopModel from '../model/laptopNew.gltf';

import { useMediaQuery } from "react-responsive";

gsap.registerPlugin(ScrollTrigger);

export function Laptop({ sceneRef, videoSource, imageSource }) {
  const laptopRef = useRef(null); // Reference for the laptop object
  
  const laptop = useGLTF(laptopModel);

  const isMobile = useMediaQuery({ maxWidth: "600px" });

  useEffect(() => {
    if(isMobile) {
      // Create a TextureLoader to load the image
      const textureLoader = new THREE.TextureLoader();

      // Load the image and apply it as a texture
      textureLoader.load(
        imageSource,
        (texture) => {
          texture.flipY = false; // Disable vertical flipping
          // Find the plane mesh
          const planeMesh = laptop.nodes.Top.children.find(child => child.name === 'Plane');

          // Apply the image texture
          planeMesh.material.map = texture;
          planeMesh.material.needsUpdate = true; // Force material update
        },
        undefined,
        (error) => {
          console.error('An error occurred while loading the texture:', error);
        }
      );
    } else{
        // Create the video element
        const video = document.createElement('video');
        video.src = videoSource;
        video.crossOrigin = 'Anonymous'; // Ensure CORS is correctly handled
        video.loop = true;
        video.muted = true;
        video.playsInline=true; 
        video.setAttribute('playsinline', true);
        video.setAttribute('webkit-playsinline', true);
        video.play();

        // Create a VideoTexture
        const videoTexture = new THREE.VideoTexture(video);
        videoTexture.flipY = false; // Disable vertical flipping

        // Find the plane mesh
        const planeMesh = laptop.nodes.Top.children.find(child => child.name === 'Plane');

        // Apply the video texture
        planeMesh.material.map = videoTexture;
        planeMesh.material.needsUpdate = true; // Force material update
    }
  }, [laptop, videoSource, imageSource]);

  useGSAP(() => {
    if (!laptopRef.current) return;

    ScrollTrigger.observe({
      trigger: 'body',
      type: "touch,pointer", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
      onUp: () => { ScrollTrigger.update(); },
    });

    const handleResize = () => {
        const isNarrowScreen = window.innerWidth < 1000;
        const isMobileScreen = window.innerWidth < 600;
        const newZPosition = isMobileScreen ? -7.5 : isNarrowScreen ? -5.5 : -1.5;

        // Update position-z based on screen width
        laptopRef.current.position.z = newZPosition;

        const targetObject = laptopRef.current.getObjectByName('Top');

        gsap.fromTo(targetObject.rotation,
          { x: Math.PI / 2 }, // Start rotation
          {
            x: Math.PI / 1, // End rotation
            scrollTrigger: {
              trigger: sceneRef.current,
              start: 'top top',
              end: (window.innerWidth / 10) * 8,
              scrub: true,

              invalidateOnRefresh: true, // Helps to re-calculate positions on refresh
              
            },
          }
        );

        gsap.fromTo(laptopRef.current.rotation,
          { y: Math.PI * 2 }, // Start rotation
          {
            y: Math.PI / 1, // End rotation
            scrollTrigger: {
              trigger: sceneRef.current,
              start: 'top top',
              end: (window.innerWidth / 10) * 8,
              scrub: true,
              
              invalidateOnRefresh: true, // Helps to re-calculate positions on refresh
              
            },
          }
        );

        ScrollTrigger.refresh();
    };

    handleResize(); // Initial check on component mount

    window.addEventListener('resize', handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener
      ScrollTrigger.getAll().forEach(trigger => trigger.kill()); // Clean up GSAP ScrollTriggers
    };
  }, [sceneRef, laptopRef]);

  return (
    <group dispose={null}>
      <primitive
        object={laptop.scene}
        rotation-x={0.25}
        position-y={-1.25}
        position-z={-1.5} // Initial position, will be updated by resize function if needed
        ref={laptopRef}
      />
    </group>
  );
}

export default function SceneProject({ videoSource, imageSource  }) {
  const sceneRef = useRef(null); // Reference for the scene object

  return (
    <Canvas
      className={classes.sceneContainer}
      ref={sceneRef}
      camera={{
        fov: 45,
        near: 0.1,
        far: 2000,
        position: [0, 0, 3],
      }}
    >
      <Environment preset="warehouse" />
      <Laptop sceneRef={sceneRef} videoSource={videoSource} imageSource={imageSource}/>
    </Canvas>
  );
}
