import React, { useEffect, useRef, useContext } from 'react';
import gsap from 'gsap';
import classes from './SolutionsServices.module.css';

// import { useTranslation } from '../hooks/useTranslation';
import TranslationContext from '../hooks/TranslationProvider';


export default function ServicesSectionV1() {
    // const { t, changeLanguage, language } = useTranslation();
    const { t } = useContext(TranslationContext);

    const ourServicesRef = useRef(null);
    const serviceNameRefs = useRef([]);
    const bentoServiceRefs = useRef([]);
    // Function to add refs to the array
    const addToRefs = (el) => {
        if (el && !serviceNameRefs.current.includes(el)) {
            serviceNameRefs.current.push(el);
        }
    };
    const addToBentoRefs = (el) => {
        if (el && !bentoServiceRefs.current.includes(el)) {
            bentoServiceRefs.current.push(el);
        }
    };

    // new part, to separate different sections of the bento and animate them as you scroll
    const firstSectionRefs = useRef([]);
    const secondSectionRefs = useRef([]);
    const secondSectionStartRef = useRef(null);
    const addToFirstSectionRefs = (el) => {
        if (el && !firstSectionRefs.current.includes(el)) {
            firstSectionRefs.current.push(el);
        }
    };

    const addToSecondSectionRefs = (el) => {
        if (el && !secondSectionRefs.current.includes(el)) {
            secondSectionRefs.current.push(el);
        }
    };

    const servicesAnimations = () => {
        const bentoElements = document.querySelectorAll(`.${classes.bentoGrid} div`);
        const timeline2 = gsap.timeline({
            scrollTrigger: {
                trigger: ourServicesRef.current,  // Element to watch
                start: 'bottom 80%',         // When to start the animation (e.g., when the top of the element is 80% from the top of the viewport)
                end: '+=1',             // The point at which the animation ends (can be a scroll distance or time-based)
                // scrub: true,               // Smoothly animate with scroll
                // markers: true,            // Add markers for debugging
                toggleActions: "play none none reverse", // reset on scroll out of view
                // onEnter: () => timeline2.play(),  // Start the animation when entering the viewport
                // onLeaveBack: () => timeline2.pause(), // Pause when scrolling back
            }
        });
        // ANIMATED NUESTROS SERVICIOS BENTO BOX
        timeline2.fromTo(ourServicesRef.current,
            { opacity:1, },
            { width: '100%', duration: 2, ease: "power3.inOut" },
        );
        bentoServiceRefs.current.forEach(ref => {
            timeline2.fromTo(ref,
                {opacity: 0,},
                {
                    opacity: 1, duration: 1, ease: "power3.inOut"
                }, 0);
        });
        serviceNameRefs.current.forEach(ref => {
            timeline2.to(ref, {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: "power3.out"
            }, 1);
        });
        bentoElements.forEach(element => {
            timeline2.to(element, {
                opacity: 1,
                duration: 1.5,
                ease: 'power2.out'
            }, 1.5)
        });
        // NEW ANIMATION FOR DIFFERENT SECTIONS
        // firstSectionRefs.current.forEach(ref => {
        //     timeline2.to(ref, {
        //         opacity: 1,
        //         duration: 1.5,
        //         ease: 'power2.out'
        //     }, 1.25)
        // });

        const tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: secondSectionStartRef.current,  // Element to watch
                start: 'top 75%',         // When to start the animation (e.g., when the top of the element is 80% from the top of the viewport)
                end: '100% 75%',             // The point at which the animation ends (can be a scroll distance or time-based)
                // scrub: true,               // Smoothly animate with scroll
                // markers: true,            // Add markers for debugging
                toggleActions: "play none none reverse", // reset on scroll out of view
            }
        });
        // secondSectionRefs.current.forEach(ref => {
        //     tl3.to(ref, {
        //         opacity: 1,
        //         duration: 1.5,
        //         ease: 'power2.out'
        //     }, 0.25)
        // });
    }

    useEffect(() => {
        servicesAnimations();
    }, []);

    return (
        <section className={classes.servicesSection}>
            <div className={classes.bentoGrid}>
                <div ref={ourServicesRef} className={classes.ourServicesGrid}>
                    <h1>{t('services.title')}</h1>
                </div>
                <div ref={addToBentoRefs} className={classes.firstServiceGrid}>
                    <h2 ref={addToRefs} className={classes.serviceName}>{t('services.erp.title')}</h2>
                </div>
                <div ref={addToBentoRefs} className={classes.secondServiceGrid}>
                    <h2 ref={addToRefs} className={classes.serviceName}>{t('services.ecommerce.title')}</h2>
                </div>
                <div ref={addToBentoRefs} className={classes.thirdServiceGrid}>
                    <h2 ref={addToRefs} className={classes.serviceName}>{t('services.chat.title')}</h2>
                </div>
                <div ref={addToFirstSectionRefs} className={classes.fourthServiceGrid}>
                    <div ref={addToFirstSectionRefs} className={classes.servicesBodyText}>
                        {t('services.erp.description')}
                    </div>
                </div>
                <div ref={addToFirstSectionRefs} className={classes.fifthServiceGrid}>
                    <img ref={addToFirstSectionRefs} src='/images/stock/stock-ecomm.jpeg' alt=''/>
                </div>
                <div ref={addToFirstSectionRefs} className={classes.sixthServiceGrid}>
                    <div ref={addToFirstSectionRefs} className={classes.servicesBodyText}>
                        {t('services.chat.description')}
                    </div>
                </div>
                <div ref={addToFirstSectionRefs} className={classes.seventhServiceGrid}>
                    <img ref={addToFirstSectionRefs} src='/images/stock/stock-erp-2.webp' alt=''/>
                </div>
                <div ref={addToFirstSectionRefs} className={classes.eighthServiceGrid}>
                    <img ref={addToFirstSectionRefs} src='/images/stock/stock-chat.jpeg' alt=''/>
                </div>
                <div ref={addToFirstSectionRefs} className={classes.ninthServiceGrid}>
                    <div ref={addToFirstSectionRefs} className={classes.servicesBodyText}>
                        {t('services.description1')}
                    </div>
                </div>
                <div ref={(el)=> {secondSectionStartRef.current = el; addToSecondSectionRefs(el);}} className={classes.tenthServiceGrid}>
                    <div  ref={addToSecondSectionRefs} className={classes.servicesBodyText}>
                        {t('services.description2')}
                    </div>
                </div>
                <div ref={addToSecondSectionRefs} className={classes.eleventhServiceGrid}>
                    <img ref={addToSecondSectionRefs} src='/images/stock/stock-desk.jpg' alt=''/>
                </div>
                <div className={classes.whyUsGrid}>
                    <h2>{t('whyUs.title')}</h2>
                </div>
                <div className={classes.whyUsFirst}>
                    <h3>{t('whyUs.grid1.title')}</h3>
                    <h4>{t('whyUs.grid1.description')}</h4>
                </div>
                <div className={classes.whyUsSecond}>
                    <h3>{t('whyUs.grid2.title')}</h3>
                    <h4>{t('whyUs.grid2.description')}</h4>
                </div>
                <div className={classes.whyUsThird}>
                    <h3>{t('whyUs.grid3.title')}</h3>
                    <h4>{t('whyUs.grid3.description')}</h4>
                </div>
                <div className={classes.whyUsFourth}>
                    <h3>{t('whyUs.grid4.title')}</h3>
                    <h4>{t('whyUs.grid4.description')}</h4>
                </div>
                <div className={classes.twelfthServiceGrid}>
                    <img src='/images/stock/stock-labyrinth.jpg' alt=''/>
                </div>
                <div className={classes.thirteenthServiceGrid}>
                    <img src='/images/stock/stock-quality.jpg' alt=''/>
                </div>
            </div>
        </section>
    )
}
