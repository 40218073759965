import classes from './Landing.module.css';
import { useState, useContext } from 'react';
import { Link } from "react-router-dom";
import TranslationContext from '../hooks/TranslationProvider';
import SceneLanding from '../components/SceneLanding'


export default function Landing() {
    const { t } = useContext(TranslationContext);
    const [hoveredContainer, setHoveredContainer] = useState(null);

    const handleHover = (hover) => {
        setHoveredContainer(() => hover)
    }

    const handleLeave = () => {
        setHoveredContainer(() => null);
    }

    return(
        <>
        <div className={classes.landingContainer}>
            <Link
            onMouseEnter={() => handleHover(1)}
            onMouseLeave={handleLeave}
            className={classes.landingElement}
            to="/studio" 
            >
                <div className={classes.textContainer}>
                    <h1>STUDIO</h1>
                    <p>{t('hero.studio')}</p>
                </div>
            </Link>
            <SceneLanding hoveredContainer = {hoveredContainer}/>
            <Link
            onMouseEnter={() => handleHover(2)}
            onMouseLeave={handleLeave}
            className={classes.landingElement}
            to="/solutions" 
            >
                <div className={classes.textContainer}>
                    <h1>SOLUTIONS</h1>
                    <p>{t('hero.solutions')}</p>
                </div>
            </Link>
            <div className={classes.aztechMxContainer}>
                <div className={classes.aztechMxHeader}>
                    AZTECH MÉXICO
                </div>
            </div>
        </div>
        </>
    )
}