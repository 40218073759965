import React, { useRef, useEffect } from 'react';
import GlslCanvas from 'glslCanvas';

const NextProjectShader = ({ startColor, endColor }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        
        // Create GLSLCanvas instance
        const glslCanvas = new GlslCanvas(canvas);

        const shaderCode = `
            #ifdef GL_ES
            precision highp float;
            #endif

            uniform float u_time;
            uniform vec2 u_resolution;

            varying vec2 v_texcoord;

            void main(void) {
                vec2 fragCoord = v_texcoord * u_resolution;
                vec4 startColor = vec4(${startColor}); // Red
                vec4 endColor = vec4(${endColor});   // Yellow

                float currentAngle = -(u_time * 36.0); // Rotation angle based on time

                vec2 uv = fragCoord / u_resolution;  // Normalized coordinates
                vec2 origin = vec2(0.5, 0.5);        // Center of the image
                uv -= origin;                        // Move UV to center

                float angle = radians(90.0) - radians(currentAngle) + atan(uv.y, uv.x);

                float len = length(uv);
                uv = vec2(cos(angle) * len, sin(angle) * len) + origin;

                gl_FragColor = mix(startColor, endColor, smoothstep(0.0, 1.0, uv.x));
            }
        `;

        // Set the shader code
        glslCanvas.load(shaderCode);

        // Adjust canvas size to match container
        const resizeCanvas = () => {
            canvas.width = canvas.clientWidth;
            canvas.height = canvas.clientHeight;
        };

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas(); // Initial resize

        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, [startColor, endColor]);

    return (
        <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
    );
};

export default NextProjectShader;
