import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ServicesSectionV1 from './SolutionsServicesV1';
import ContactComponent from '../components/ContactComponent';
import classes from './Solutions.module.css';
import SolutionsProjectSlider from './SolutionsProjectSlider';


gsap.registerPlugin(ScrollTrigger);


export default function Solution() {
    const bentoIntro = useRef(null);
    const introVid = useRef(null);
    const introTextRef = useRef(null);

    const introHeadingAnimation = () => {
        const characters = introTextRef.current.querySelectorAll('.char');
        gsap.fromTo(
            characters,
            { opacity: 0, x: -20 },  // Initial state (hidden, slightly off to the left)
            {
                opacity: 1,
                x: 0,                 // Final state (fully visible, in place)
                stagger: 0.15,        // Stagger the animation of each character
                ease: 'power2.out',   // Smooth easing function
                duration: 0.15         // Duration of the animation per character
            }
        );
    }

    const introShrinkingAnimation = () => {
        let t1End = 'bottom 95%';
        let t1Top = 'calc(88px + 3rem)';
        let t1Height = 'calc(100vh - 88px - 2rem)';
        let t2Start = '5% 0%';
        let t2End = 'bottom 60%';

        if (window.innerWidth <= 600) {
            const remQuantity = window.innerHeight * 0.3
            t1End = 'bottom 70%'; // bottom del div llega al 60 del viewport
            t1Top = `calc(88px + ${remQuantity / 16}rem)`;
            t2Start = '30% 0%';  // 40% del div llega al 0% del viewport
            t2End = 'bottom 30%'; // bottom del div 20% del viewport
        }

        const t1 = gsap.timeline({  // It first goes down and shrinks a bit with corner rounding
            scrollTrigger: {
                trigger: bentoIntro.current,
                start: 'top 0%',
                end: t1End,
                scrub: true,
                // markers: true,
            }
        });
        t1.to(bentoIntro.current, {
            borderRadius: '28px', top: t1Top, height: t1Height, width: '96%'
        }, 0);
        t1.to(introVid.current, {
            borderRadius: '28px',
        }, 0);

        const t2 = gsap.timeline({ // then it starts shrinking as you scroll by
            scrollTrigger: {
                trigger: bentoIntro.current,  // Element to watch
                start: t2Start,    // When to start animation (when top of the element is 80% from the top of the viewport)
                end: t2End, // The point at which the animation ends (can be a scroll distance or time-based)
                scrub: true,       // Smoothly animate with scroll
                // markers: true,
                // onEnter: () => timeline.play(),  // Start the animation when entering the viewport
                // onLeaveBack: () => timeline.pause(), // Pause when scrolling back
            }
        });
        t2.to(bentoIntro.current, {
            width: '60%', height: '60%',
        }, 0);
        t2.to(introTextRef.current, { fontSize: '6vw',}, 0);  // SHRINKING TEXT
    }

    useEffect(() => {
        introHeadingAnimation();
        introShrinkingAnimation();
    }, []);

    const IntroSection = () => {
        const text = "Aztech Solutions";
        return (
            <section className={classes.solutionSection}>
                <div ref={bentoIntro} className={classes.bentoIntro}>
                    <h1 ref={introTextRef} className={classes.introHeading}>
                        {text.split('').map((char, index) => (
                            <span key={index} className="char">
                                {char}
                            </span>
                        ))}
                    </h1>
                    {/* <h1 className={classes.introHeading}>Aztech Solutions</h1> */}
                    <video ref={introVid} className={classes.introVid} autoPlay loop muted playsInline>
                    {/* <video className={classes.introVid} width="1920" height="1080" autoplay loop muted playsinline webkit-playsinline> */}
                        <source src="/video/intro-vid-hd_1920_1080_25fps.mp4" type="video/mp4" />
                    </video>
                </div>
            </section>
        )
    }

    const ServiciosSectionV2 = () => {
        return (
            <section>

            </section>
        )
    }

    return(
        <>
        <IntroSection />
        <ServicesSectionV1 />
        <SolutionsProjectSlider />
        <section className={classes.contactSection}>
            <div className={classes.bentoContact}>
                <ContactComponent/>
                {/* <h3>Una división de Aztech México</h3> */}
            </div>
        </section>
        </>
    )
}

