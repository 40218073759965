import AnimatedText from '../components/AnimatedText';
import StudioShader from '../components/StudioShader';
import StudioWorkSection from '../components/StudioWorkSection';
import classes from './Studio.module.css';
import StudioAbout from '../components/StudioAbout';
import ContactComponent from '../components/ContactComponent';
import { useEffect, useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function Studio(){
    const contactContainer = useRef(null);
    const aboutUsContainer = useRef(null);

    useEffect(() => {
        console.log('contactContainer:', contactContainer.current);
        console.log('aboutUsContainer:', aboutUsContainer.current);

        if(contactContainer.current && aboutUsContainer.current){
            gsap.fromTo(contactContainer.current, 
                { top: "100vh" },  // Initial state: positioned 100vh below
                { 
                  top: "0vh",       // Final state: positioned at the top
                  ease: "none", 
                  scrollTrigger: {
                    trigger: aboutUsContainer.current, // Pin when .about-container is in view
                    start: "bottom bottom", // When the .contact section starts
                    end: "bottom top", // Duration for the animation
                    scrub: true,       // Smooth transition based on scroll
                    pin: true,         // Pin the .contact section
                    pinSpacing: true,
                    onEnter: () => console.log("Animation triggered"), // Log to confirm
                  }
                }
              );
        } else{
            console.error("Refs are null");
        }
    }, []);

    return(
        <div className={classes.container}>
            <div className={classes.shaderContainer}> 
                <StudioShader />
            </div>

            <div className={classes.headerContainer}>
                <div className={classes.titleContainer}>
                    <h1  className={classes.title1}>AZTECH</h1>
                    <h1  className={classes.title2}>STUDIO</h1>
                    <AnimatedText />
                </div>

                <div className={classes.scrollContainer}>
                    <svg fill="#fff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
	                    width="800px" height="800px" viewBox="0 0 421.943 421.944">
                    <g>
                    	<path d="M418.054,273.641c-17.748-11.016-33.66-23.868-48.348-38.556c-4.896-4.896-13.464-1.225-13.464,5.508
                    		c0,5.508-0.612,11.016-0.612,16.523c-53.244,9.792-106.488,14.076-160.957,14.688c-45.9,1.224-107.712,6.12-149.328-15.912
                    		c-31.824-17.136-36.72-56.917-23.256-87.517c5.508-12.852,15.3-23.256,27.54-30.6c14.076-8.568,28.152-3.672,42.84-7.344
                    		c1.836-0.612,2.448-3.06,1.224-4.896c-22.644-23.868-62.424,3.672-77.112,23.256c-20.808,27.54-22.032,67.933-3.672,96.696
                    		c26.928,42.841,91.188,39.78,134.64,41.004c68.544,2.448,140.76,0.612,208.081-14.688c0,6.12,0.612,11.628,2.448,17.748
                    		c0,1.224,0.611,1.836,1.224,2.448c-4.896,4.283-0.612,15.3,7.956,13.464c17.748-3.672,35.496-9.181,52.02-17.136
                    		C423.562,284.657,422.338,276.701,418.054,273.641z M370.93,257.729c8.568,7.344,17.136,14.688,26.928,21.42
                    		c-8.567,3.672-17.748,6.12-26.928,8.568c0-0.612,0-1.225,0-2.448C369.094,276.089,369.706,266.909,370.93,257.729z"/>
                    </g>
                    </svg>
                    <p>SCROLL</p>
                </div>
            </div>

            <div className={classes.headerAuxiliarContainer} />

            <div className={classes.contentContainer}>
                <div className={classes.featuredWork}>
                    <StudioWorkSection />
                </div>

                <div ref={aboutUsContainer} className={'aboutUs'}>
                    <StudioAbout />
                </div>

                {/* <div className='services-container'>
                    <h1>O</h1>
                </div> */}
            </div>

            <div ref={contactContainer} className='contact-studio'>
                <ContactComponent backgroundColor='#000' balls/>
            </div>
        </div>
    )
}