import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import TranslationContext from "../hooks/TranslationProvider";
import HorizontalScroll from "../components/HorizontalScroll";
import SceneProject from "../components/SceneProjects";
import MobileProjectBento from "../components/MobileProjectBento";
import NextProject from "../components/NextProject";

import classes from "./Project.module.css";

import cerm1 from "../img/cerm/cerm1.jpeg";
import cerm2 from "../img/cerm/cerm2.jpeg";
import cerm3 from "../img/cerm/cerm3.jpeg";
import cerm4 from "../img/cerm/cerm4.jpeg";
import cerm5 from "../img/cerm/cerm5.png";
import cerm6 from "../img/cerm/cerm6.png";
import cerm7 from "../img/cerm/cerm7.png";
import cerm8 from "../img/cerm/cerm8.png";
import cermVid from "../video/CERM.mp4";


export default function Cerm() {
  const { t } = useContext(TranslationContext);
  const photos = [cerm1, cerm2, cerm3, cerm4, cerm5, cerm6, cerm7, cerm8];
  const isMobile = useMediaQuery({ maxWidth: "700px" });

  return (
    <div className={classes.container}>
      <SceneProject videoSource={cermVid} imageSource={cerm5} />
      <div className="projectInfo">
        <h1>CERM-AUTO</h1>
        <h2>{t("work.cerm.title")}</h2>
        <h2>2023</h2>
        <div className={classes.scrollContainer}>
          <img src="/images/icons/arrow.svg" alt="Scroll arrow icon" className={classes.scrollContainer}/>
          <p>{t("work.scroll")}</p>
        </div>
      </div>

      <div className="projectDetailsContainer">
        <div className="projectDetailsContainerFlex">
          <div className="projectDetailsElement">
            <h3>{t("work.cerm.summary")}</h3>
            <p>{t("work.cerm.description1")}</p>
            <p>{t("work.cerm.description2")}</p>
          </div>

          <div className="projectDetailsElement">
            <h4>{t("work.cerm.whatWeDid.title")}</h4>
            <ol>
              <li>{t("work.cerm.whatWeDid.item1")}</li>
              <li>{t("work.cerm.whatWeDid.item2")}</li>
              <li>{t("work.cerm.whatWeDid.item3")}</li>
              <li>{t("work.cerm.whatWeDid.item4")}</li>
              <li>{t("work.cerm.whatWeDid.item5")}</li>
            </ol>
          </div>

          <div className="projectDetailsElement">
            <h4>{t("work.cerm.client.title")}</h4>
            <ol>
              <li>{t("work.cerm.client.name")}</li>
            </ol>
            <h4 style={{ marginTop: "20px" }}>
              {t("work.cerm.techUsed.title")}
            </h4>
            <ol>
              <li>{t("work.cerm.techUsed.tech1")}</li>
              <li>{t("work.cerm.techUsed.tech2")}</li>
            </ol>
          </div>

          <div className="projectDetailsElement">
            <a href="https://cerm-auto.com" target="_blank" rel="noreferrer">
              <span>{t("work.liveProject")}</span>
              <svg viewBox="0 0 100 100" width="30" height="30">
                <circle cx="50" cy="50" r="45" />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div className="projectImages">
        {isMobile && <MobileProjectBento photos={photos} />}
        {!isMobile && <HorizontalScroll photos={photos} />}
      </div>

      <div className="nextProjectContainer">
        <NextProject destiny={"mercyfit"} />
      </div>
    </div>
  );
}
