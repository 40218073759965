import { NavLink } from "react-router-dom";

import classes from './MainNavigation.module.css';

import {IoClose, IoMenu} from 'react-icons/io5';
import { useMediaQuery } from "react-responsive";
import { useState } from "react";

import React, { useContext } from 'react';
// import { useTranslation } from '../hooks/useTranslation';
import TranslationContext from '../hooks/TranslationProvider';

import logo from '../img/logo.png';

const studio = <path className={classes.scribble} d="
M5,20
Q10,10 20,15
T30,20
T35,30
T25,35
T15,30
T10,40
T20,35
T30,30
T40,25
T35,15
T20,10
T10,15
T5,20
M10,25
Q15,20 20,25
T30,25
T25,30
T15,30
T10,25
Z
"/>;

const solutions =  <path className={classes.scribble} d="
M10,10
Q20,5 30,10
T45,20
T35,30
T20,25
T10,30
T5,20
T10,10
M15,20
Q20,15 25,20
T35,25
T25,30
T15,25
T10,20
Z
" />;

const work = <path  className={classes.scribble} d="
M5,25
Q15,10 30,15
T45,25
T40,35
T30,40
T20,35
T10,30
T5,25
M10,30
Q15,25 20,30
T30,35
T25,30
T15,25
T10,30
Z
" />;

const contact = <path  className={classes.scribble} d="
M5,20
Q10,10 20,15
T30,10
T40,20
T30,30
T20,25
T10,30
T5,20
M10,25
Q15,20 20,25
T30,30
T25,25
T15,20
T10,25
Z
" />;

const routes = [
    { path: '/studio', label: 'STUDIO', svg: studio},
    { path: '/solutions', label: 'SOLUTIONS', svg: solutions},
    { path: '/work', label: 'WORK', svg: work},
    { path: '/contact', label: 'CONTACT', svg: contact},
];

function MainNavigation(){
    const [showMenu, setShowMenu] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: "1000px" });

    const toggleMenu = () => {
      setShowMenu(!showMenu);
    };

    const closeMenuOnMobile = () => {
        if (isMobile) {
        setShowMenu(false);
      }
    };

    // const { t, changeLanguage, language } = useTranslation();
    const { t, changeLanguage, language } = useContext(TranslationContext);
    const handleChange = (event) => {
        const selectedLanguage = event.target.value;
        changeLanguage(selectedLanguage); // Update the language across all components
    };

    return (
        <header className={`${classes.header} ${!showMenu ? classes.mixed : ""}`}>
            <nav className={classes.navbar}>
                <NavLink 
                    to="/" 
                    end
                > 
                    <img src={logo} className={classes.logo} alt="logo"/>
                </NavLink>
                <ul className={`${classes.list} ${showMenu ? classes.showMenu : ""}`}>
                    {
                        routes.map((route) => (
                            <li key={route.path}>
                                    <NavLink 
                                        to={route.path}
                                        className={({isActive}) => isActive ? classes.active : undefined}
                                        onClick={closeMenuOnMobile}
                                    >
                                        {route.label}
                                        <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
                                        {route.svg}
                                        </svg>
                                    </NavLink>
                            </li>
                        )
                    )}
                    <div className={classes.languageToggle}>
                        <img className={classes.langImg} src="/images/icons/lang-icon.svg" />
                    
                        <select 
                        value={language} 
                        onChange={handleChange} 
                        className={classes.select}
                        >
                        {/* <select 
                        value={language} 
                        onChange={(e) => changeLanguage(e.target.value)} 
                        className={classes.select}
                        > */}
                            <option value="en">ENGLISH</option>
                            <option value="es">ESPAÑOL</option>
                        </select>
                    </div>
                    {
                        isMobile && 
                        <div className={classes.nav__close} id="nav-close" onClick={toggleMenu}>
                            <IoClose />
                        </div>
                    }
                </ul>
            </nav>

            {
                isMobile && <div className={classes.nav__toggle} id="nav-toggle" onClick={toggleMenu}>
                <IoMenu />
            </div>
            }
        </header>
    );
}

export default MainNavigation;