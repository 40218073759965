import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import TranslationContext from "../hooks/TranslationProvider";
import HorizontalScroll from "../components/HorizontalScroll";
import SceneProject from "../components/SceneProjects";
import MobileProjectBento from "../components/MobileProjectBento";
import NextProject from "../components/NextProject";
import classes from "./Project.module.css";

import sys1 from "../img/sysnot/sys1.jpeg";
import sys2 from "../img/sysnot/sys2.png";
import sys3 from "../img/sysnot/sys3.png";
import sys4 from "../img/sysnot/sys4.png";
import sys5 from "../img/sysnot/sys5.png";
import sys6 from "../img/sysnot/sys6.png";
import sysVid from "../video/SYSNOT.mp4";


export default function Sysnot() {
  const { t } = useContext(TranslationContext);
  const photos = [sys1, sys2, sys3, sys4, sys5, sys6];
  const isMobile = useMediaQuery({ maxWidth: "700px" });

  return (
    <div className={classes.container}>
      <SceneProject videoSource={sysVid} imageSource={sys2} />
      <div className="projectInfo">
        <h1>SYSNOT</h1>
        <h2>{t("work.sysnot.title")}</h2>
        <h2>2024</h2>
        <div className={classes.scrollContainer}>
          <img src="/images/icons/arrow.svg" alt="Scroll arrow icon" className={classes.scrollContainer}/>
          <p>{t("work.scroll")}</p>
        </div>
      </div>

      <div className="projectDetailsContainer">
        <div className="projectDetailsContainerFlex">
          <div className="projectDetailsElement">
            <h3>{t("work.sysnot.summary")}</h3>
            <p>{t("work.sysnot.description1")}</p>
            <p>{t("work.sysnot.description2")}</p>
          </div>

          <div className="projectDetailsElement">
            <h4>{t("work.sysnot.whatWeDid.title")}</h4>
            <ol>
              <li>{t("work.sysnot.whatWeDid.item1")}</li>
              <li>{t("work.sysnot.whatWeDid.item2")}</li>
              <li>{t("work.sysnot.whatWeDid.item3")}</li>
              <li>{t("work.sysnot.whatWeDid.item4")}</li>
              <li>{t("work.sysnot.whatWeDid.item5")}</li>
              <li>{t("work.sysnot.whatWeDid.item6")}</li>
            </ol>
          </div>

          <div className="projectDetailsElement">
            <h4>{t("work.sysnot.client.title")}</h4>
            <ol>
              <li>{t("work.sysnot.client.name")}</li>
            </ol>
            <h4 style={{ marginTop: "20px" }}>{t("work.sysnot.techUsed.title")}</h4>
            <ol>
              <li>{t("work.sysnot.techUsed.frontend")}</li>
              <li>{t("work.sysnot.techUsed.backend")}</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="projectImages">
        {isMobile && <MobileProjectBento photos={photos} />}
        {!isMobile && <HorizontalScroll photos={photos} />}
      </div>

      <div className="nextProjectContainer">
        <NextProject destiny={"tsmorales"} />
      </div>
    </div>
  );
}
