import { useEffect, useState, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Sphere } from '@react-three/drei';
import * as THREE from 'three';

const MovingSphere = ({ bounds }) => {
    const ref = useRef();
    const [speed] = useState(() => ({
        x: (Math.random() - 0.5) * 0.045,
        y: (Math.random() - 0.5) * 0.045,
        z: (Math.random() - 0.5) * 0.045,
    }));

    useFrame(() => {
        ref.current.position.x += speed.x;
        ref.current.position.y += speed.y;
        ref.current.position.z += speed.z;

        // Bounce back when reaching the defined bounds
        if (ref.current.position.x < bounds.minX || ref.current.position.x > bounds.maxX) speed.x *= -1;
        if (ref.current.position.y < bounds.minY || ref.current.position.y > bounds.maxY) speed.y *= -1;
        if (ref.current.position.z < bounds.minZ || ref.current.position.z > bounds.maxZ) speed.z *= -1;
    });

    return (
        <Sphere ref={ref} args={[0.1, 32, 32]} position={[Math.random() * 4 - 2, Math.random() * 4 - 2, Math.random() * 4 - 2]}>
            <meshStandardMaterial metalness={2.0} attach="material" color={new THREE.Color(Math.random(), Math.random(), Math.random())} />
        </Sphere>
    );
};

export default function SceneStudio(){
    const [aspectRatio, setAspectRatio] = useState(window.innerWidth / window.innerHeight);
    const [frustumSize, setFrustumSize] = useState(4);

    // Resize handler
    const handleResize = () => {
        setAspectRatio(window.innerWidth / window.innerHeight);
        setFrustumSize(4); // You can adjust the size of the frustum here if needed
    };

    // Set up and clean up the resize listener
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <Canvas style={{ height: '100vh', width: '100vw' }}
        camera={{
            left: (-frustumSize * aspectRatio) / 2,
            right: (frustumSize * aspectRatio) / 2,
            top: frustumSize / 2,
            bottom: -frustumSize / 2,
            near: -1000,
            far: 1000,
            position: [0, 0, 1], // Adjust the position if needed
            zoom: 1
          }}
          orthographic
          shadows>
            <ambientLight intensity={0.2} />
            <directionalLight
              castShadow
              intensity={2.5}
              position={[5, 5, 5]}
              shadow-mapSize-width={1024}
              shadow-mapSize-height={1024}
            />
            {/* Render multiple spheres */}
            {Array.from({ length: 20 }).map((_, index) => (
              <MovingSphere key={index} bounds={{
                minX: -(window.innerWidth / 2)*0.004,
                maxX: (window.innerWidth / 2)*0.004,
                minY: -(window.innerHeight / 2)*0.004,
                maxY: (window.innerHeight / 2)*0.004,
                minZ: -2,
                maxZ: 2
              }} />
            ))}
        </Canvas>
    );
}
