import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { gsap } from "gsap";

const PageTransition = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [prevLocation, setPrevLocation] = useState(location.pathname);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const overlayRef = useRef(null);

  useEffect(() => {
    if (location.pathname !== prevLocation) {
      setIsTransitioning(true);

      // GSAP Timeline for transition animation
      const tl = gsap.timeline({
        onComplete: () => {
          setIsTransitioning(false);
          setPrevLocation(location.pathname);
        },
      });

      // Exit animation before changing page
      tl.to(overlayRef.current, {
        top: '0%',
        duration: 0.6,
        // transformOrigin: "bottom",
        ease: "power3.inOut",
      })
      .to(overlayRef.current, {
        top: '120%',
        duration: 0.6,
        // transformOrigin: "top",
        ease: "power3.inOut",
        delay: 0.6,
      });

      // Delay navigation until transition is complete
      setTimeout(() => {
        navigate(location.pathname, { replace: true });
      }, 1200); // Match the total duration of the animation (0.5 + 0.5 seconds)
    }
  }, [location, prevLocation, navigate]);

  return (
    <>
      <div className="transition-overlay" ref={overlayRef}></div>
      {!isTransitioning && children}
    </>
  );
};

export default PageTransition;
