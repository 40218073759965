import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react'
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const HorizontalScroll = ({ photos }) => {
  const containerRef = useRef(null);
  const wrapperRef = useRef(null);

  useGSAP(() => {
    const createScrollAnimation = () => {
      const containerWidth = containerRef.current.scrollWidth;
      const viewportWidth = window.innerWidth;

      gsap.fromTo(containerRef.current,
        {
          left: 0, // Starting position
        },
        {
          left: () => -(containerWidth - viewportWidth), // Ending position
          ease: "none",
          scrollTrigger: {
            trigger: wrapperRef.current,
            pin: true,
            scrub: true,
            start: 'top top',
            end: () => `+=${(containerWidth - viewportWidth)}`, // End based on container width
            pinSpacing: true,

            invalidateOnRefresh: true, // Helps to re-calculate positions on refresh
            fastScrollEnd: true,
          },
        }
      );
    };

    createScrollAnimation(); // Initial setup

    const handleResize = () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill()); // Kill existing ScrollTriggers
      createScrollAnimation(); // Recreate the animation with updated dimensions
      ScrollTrigger.refresh();
    };

    const handleTouchStart = () => {
      ScrollTrigger.refresh();
    };

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  });

  return (
    <div className="horizontal-scroll-wrapper" ref={wrapperRef}>
      <div className="horizontal-scroll-container" ref={containerRef}>
        {photos.map((photo, index) => (
          <div className="scroll-item" key={index}>
            <img src={photo} alt={`photo-${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalScroll;
