import { useContext } from "react";
import TranslationContext from "../hooks/TranslationProvider";

import SceneProject from "../components/SceneProjects";
import classes from "./Project.module.css";

import notaria137 from "../img/notaria137/notaria137-browser.png"
import not137vid from "../video/notaria137.mp4";
import NextProject from "../components/NextProject";

export default function Notaria137() {
  const { t } = useContext(TranslationContext);

  return (
    <div className={classes.container}>
      <SceneProject videoSource={not137vid} imageSource={notaria137} />
      <div className="projectInfo">
        <h1>Notaría 137</h1>
        <h2>{t("work.notaria137.title")}</h2>
        <h2>2024</h2>

        <div className={classes.scrollContainer}>
          <img src="/images/icons/arrow.svg" alt="Scroll arrow icon" className={classes.scrollContainer}/>
          <p>{t("work.scroll")}</p>
        </div>
      </div>

      <div className="projectDetailsContainer">
        <div className="projectDetailsContainerFlex">
          <div className="projectDetailsElement">
            <h3>{t("work.notaria137.summary")}</h3>
            <p>{t("work.notaria137.description1")}</p>
            <p>{t("work.notaria137.description2")}</p>
          </div>

          <div className="projectDetailsElement">
            <h4>{t("work.notaria137.whatWeDid.title")}</h4>
            <ol>
              <li>{t("work.notaria137.whatWeDid.item1")}</li>
              <li>{t("work.notaria137.whatWeDid.item2")}</li>
              <li>{t("work.notaria137.whatWeDid.item3")}</li>
              <li>{t("work.notaria137.whatWeDid.item4")}</li>
            </ol>
          </div>
          <div className="projectDetailsElement">
            <h4>{t("work.notaria137.client.title")}</h4>
            <ol>
              <li>{t("work.notaria137.client.name")}</li>
            </ol>

            <h4 style={{ marginTop: "20px" }}>
              {t("work.notaria137.techUsed.title")}
            </h4>
            <ol>
              <li>{t("work.notaria137.techUsed.tech1")}</li>
              <li>{t("work.notaria137.techUsed.tech2")}</li>
              <li>{t("work.notaria137.techUsed.tech3")}</li>
              <li>{t("work.notaria137.techUsed.tech4")}</li>
            </ol>
          </div>
          <div className="projectDetailsElement">
            <a href="https://notaria137.mx/" target="_blank" rel="noreferrer">
              <span>{t("work.liveProject")}</span>
              <svg viewBox="0 0 100 100" width="30" height="30">
                <circle cx="50" cy="50" r="45" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="nextProjectContainer">
        <NextProject destiny={"sysnot"} />
      </div>
    </div>
  );
}
