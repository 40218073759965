import React, { useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';
import { extend, useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import * as THREE from 'three';

// Define the fragment shader (GLSL)
const fragmentShader = `
precision highp float;

uniform float u_time;
uniform float seed;
varying vec2 v_texcoord;

float rand(vec2 n) { 
    return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
}

// Simplex noise function
float noise(vec2 p){
    vec2 ip = floor(p);
    vec2 u = fract(p);
    u = u*u*(3.0-2.0*u);
    
    float res = mix(
        mix(rand(ip), rand(ip + vec2(1.0, 0.0)), u.x),
        mix(rand(ip + vec2(0.0, 1.0)), rand(ip + vec2(1.0, 1.0)), u.x), u.y);
    return res * res;
}

float fbm(vec2 x) {
    float v = 0.0;
    float a = 0.5;
    vec2 shift = vec2(100);
    mat2 rot = mat2(cos(0.5), sin(0.5), -sin(0.5), cos(0.5));
    for (int i = 0; i < 5; ++i) {
        v += a * noise(x);
        x = rot * x * 2.0 + shift;
        a *= 0.5;
    }
    return v;
}

mat2 rotation2d(float angle) {
    float s = sin(angle);
    float c = cos(angle);

    return mat2(
        c, -s,
        s, c
    );
}

vec3 hsv2rgb(vec3 c) {
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

void main() {
    vec2 uv = v_texcoord;

    // Slow and smooth wave movement
    float timeFactor = u_time * 0.02; // Slower movement for a more relaxed effect
    vec2 movement = vec2(sin(timeFactor), cos(timeFactor)) * 0.3; // Reduced movement intensity
    vec2 uvMovement = uv + movement;
    
    // Compute FBM with smooth transition
    float fbmValue = fbm(uvMovement * 1.5 + seed) * 0.5;
    fbmValue += 0.5; // Center the value around 0.5

    // Generate colors based on FBM value
    float hue = u_time * 0.05 + seed; // Slow color change
    vec3 hsv1 = vec3(hue, 0.4, 0.7); // Softer color
    vec3 hsv2 = vec3(hue + 0.2, 0.5, 0.6); // Slightly different color
    
    vec3 rgb1 = hsv2rgb(hsv1);
    vec3 rgb2 = hsv2rgb(hsv2);
    
    vec4 color1 = vec4(rgb1, 1.0);
    vec4 color2 = vec4(rgb2, 1.0);

    // Smooth transition between colors
    float smoothMix = smoothstep(0.4, 0.6, fbmValue);
    vec4 color = mix(color1, color2, smoothMix);

    // Add a blurred circle at the center
    float dist = length(uv - 0.5) * 2.5; // Distance from the center
    float circleRadius = 0.75; // Radius of the circle
    float circle = smoothstep(circleRadius, circleRadius - 1.0, dist); // Smooth edge

    // Vignette effect around the circle
    float vignette = smoothstep(0.75, 1.75, dist);
    color.rgb *= vignette;
    
    // Compute circle color based on the FBM value and vignette
    vec3 circleColor = mix(rgb1, rgb2, smoothMix);

    // Apply the circle effect
    color.rgb = mix(color.rgb, circleColor, circle * 0.5); // Blend circle color with base color

    // Add subtle noise for a more surreal effect
    float noiseEffect = noise(uv * 5.0 + u_time * 0.1) * 0.1;
    color.rgb += noiseEffect * 0.3; // Reduced noise intensity

    gl_FragColor = vec4(color.rgb, 1.0);
}





`;

// Define the vertex shader (GLSL)
const vertexShader = `
    varying vec2 v_texcoord;

    void main() {
      v_texcoord = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }


`;

const GradientMaterial = shaderMaterial(
    { 
      u_time: 0, 
      seed: Math.random()
    },
    vertexShader,
    fragmentShader
  );
  
  extend({ GradientMaterial });
  
  const ShaderPlane = () => {
    const materialRef = useRef();
  
    useFrame(({ clock }) => {
      if (materialRef.current) {
        materialRef.current.uniforms.u_time.value = clock.getElapsedTime();
      }
    });
  
    return (
      <mesh>
        <planeGeometry args={[2, 2]} />
        <gradientMaterial ref={materialRef} />
      </mesh>
    );
  };
  
  const aspectRatio = window.innerWidth / window.innerHeight;
  const frustumSize = 1; // You can adjust the size of the frustum

  const StudioShader = () => {
    return (
      <Canvas
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          margin: 0,
          padding: 0,
          overflow: 'hidden'
        }}
        camera={{
            left: (-frustumSize * aspectRatio) / 2,
            right: (frustumSize * aspectRatio) / 2,
            top: frustumSize / 2,
            bottom: -frustumSize / 2,
            near: -1000,
            far: 1000,
            position: [0, 0, 1], // Adjust the position if needed
            zoom: 1
          }}
          orthographic
      >
        <ShaderPlane />
      </Canvas>
    );
  };
  
  export default StudioShader;