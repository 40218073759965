import { useContext } from "react";
import TranslationContext from "../hooks/TranslationProvider";
import SceneProject from "../components/SceneProjects";
import classes from "./Project.module.css";

import mercy1 from "../img/mercy/mercyfit-browser.png";
import mercyfitvid from "../video/mercyfit.mp4";
import NextProject from "../components/NextProject";


export default function MercyFit() {
  const { t } = useContext(TranslationContext);

  return (
    <div className={classes.container}>
      <SceneProject videoSource={mercyfitvid} imageSource={mercy1} />
      <div className="projectInfo">
        <h1>MercyFit</h1>
        <h2>{t("work.mercyfit.title")}</h2>
        <h2>2023</h2>

        <div className={classes.scrollContainer}>
          <img src="/images/icons/arrow.svg" alt="Scroll arrow icon" className={classes.scrollContainer}/>
          <p>{t("work.scroll")}</p>
        </div>
      </div>

      <div className="projectDetailsContainer">
        <div className="projectDetailsContainerFlex">
          <div className="projectDetailsElement">
            <h3>{t("work.mercyfit.summary")}</h3>
            <p>{t("work.mercyfit.description1")}</p>
            <p>{t("work.mercyfit.description2")}</p>
          </div>

          <div className="projectDetailsElement">
            <h4>{t("work.mercyfit.whatWeDid.title")}</h4>
            <ol>
              <li>{t("work.mercyfit.whatWeDid.item1")}</li>
              <li>{t("work.mercyfit.whatWeDid.item2")}</li>
              <li>{t("work.mercyfit.whatWeDid.item3")}</li>
              <li>{t("work.mercyfit.whatWeDid.item4")}</li>
              <li>{t("work.mercyfit.whatWeDid.item5")}</li>
              <li>{t("work.mercyfit.whatWeDid.item6")}</li>
            </ol>
          </div>

          <div className="projectDetailsElement">
            <h4>{t("work.mercyfit.client.title")}</h4>
            <ol>
              <li>{t("work.mercyfit.client.name")}</li>
            </ol>
            <h4 style={{ marginTop: "20px" }}>
              {t("work.mercyfit.techUsed.title")}
            </h4>
            <ol>
              <li>{t("work.mercyfit.techUsed.tech1")}</li>
              <li>{t("work.mercyfit.techUsed.tech2")}</li>
              <li>{t("work.mercyfit.techUsed.tech3")}</li>
              <li>{t("work.mercyfit.techUsed.tech4")}</li>
            </ol>
          </div>

          <div className="projectDetailsElement">
            <a href="https://mercyfit.com.mx/" target="_blank" rel="noreferrer">
              <span>{t("work.liveProject")}</span>
              <svg viewBox="0 0 100 100" width="30" height="30">
                <circle cx="50" cy="50" r="45" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="nextProjectContainer">
        <NextProject destiny={"notaria137"} />
      </div>
    </div>
  );
}
