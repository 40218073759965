import { useState, useEffect } from 'react';

const MobileProjectBento = ({ photos }) => {
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      const images = await Promise.all(
        photos.map((src) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
              resolve({
                src: img.src,
                naturalWidth: img.naturalWidth,
                naturalHeight: img.naturalHeight,
                aspectRatio: img.naturalWidth / img.naturalHeight,
              });
            };
          });
        })
      );
      setLoadedImages(images);
    };

    loadImages();
  }, [photos]);

  return (
    <>
        <h1 className='dynamicBentoTitle'>SCREENS</h1>
        <div className="dynamicBentoGrid">
          {loadedImages.map((image, index) => {
            const itemClass = image.aspectRatio > 1.5 ? 'wideImage' : 'regularImage';

            return (
              <div key={index} className={`dynamicBentoGridItem ${itemClass}`}>
                <img src={image.src} alt={`Bento item ${index}`} />
              </div>
            );
          })}
        </div>
    </>
  );
};

export default MobileProjectBento;
